import React from 'react';
import System from '../index';
import {graphql, useStaticQuery} from 'gatsby';

const AWS = () => {
  const connectorName = 'aws';
  const connectorImages = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/systems/aws/"}, extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        edges {
          node {
            name
            relativePath
            base
            childImageSharp {
              gatsbyImageData(width: 1140, layout: CONSTRAINED, placeholder: BLURRED, quality: 100, formats: AUTO)
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const edgesImages = connectorImages.allFile.edges;
  const dataImages = {
    imageOverview: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-overview`),
    imageFeatureOne: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-02`),
    imageFeatureTwo: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-01`),
  };

  const orderSections = [{section: 'Overview'}, {section: 'FeaturesTwoCol'}, {section: 'FeaturesOneCol'}];

  return <System name={connectorName} images={dataImages} orderSections={orderSections} />;
};
export default AWS;
